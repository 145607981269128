/* Custom CSS */
a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  color: #cf7b3a;
  cursor: pointer;
}

.hover-underline-animation::after {
  content: '';
  position: absolute;
  width: 50%;
  transform: scaleX(0);
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #cf7b3a;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

header {
  position: fixed;
  top: 0;
  width: 100%;
  height: 80px;
  background: #fff;
}

::-webkit-scrollbar {
  display: none;
}

nav {
  width: 960px;
  height: 80px;
  margin: 0 auto;
}

nav ul {
  margin: 20px 0 0;
}

nav ul li {
  display: inline-block;
  margin: 0 30px 0 0;
}

a {
  color: #4D4D4D;
  font-family: sans-serif;
  text-transform: uppercase;
  text-decoration: none;
  line-height: 42px;
}

.active {
  color: #2dbccb;
}

#root {
  width: 100%;
  height: 100%;
}

.content {
  width: 100%;
  height: 100%;
}

.content>section {
  width: 100%;
  height: 100%;
}

.content>#steps {
  width: 100%;
  height: 50%;
}


#home {
  background: #2dbccb;
}

#aboutus {
  background: white;
}

#steps {
  background: #64635f;
}

#services {
  background-color: #eb7e7f;
}

#packages {
  background-color: #415c71;
}

@media (max-width: 600px) { 
  .skip1Container {
      height: 100%;
  }
  .skip2Container {
      height: 100%;
  }
  .skip3Container {
      height: 100%;
  }
  .skip4Container {
      height: 100%;
  }
}

@media (min-width: 900px) and (max-width: 2100px) {
  .skip1Container {
      margin-top:20%;
      height: 78.5%;
  }
  .skip2Container {
      margin-top:40%;
      height: 58.5%;
  }
  .skip3Container {
      margin-top:60%;
      height: 39.3%;
  }
  .skip4Container {
      margin-top:80%;
      height: 18.5%;
  }
} 

.centerTest {
  display: block;
  margin: 0 auto;
}

.accordionTitleTest{
  color:red;
  width: 100%;
  display: block;
  float: left;
}

.accButton {
  cursor: pointer; 
  background-color: transparent;
  padding: 18px;
  width: 100%;
  border-top:0px;
  border-left:0px;
  border-right: 0px;
  border-bottom: 0.5px solid black;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.accButton:after {
  float:right;
  content: '\002B';
}

.accButton[aria-expanded="true"]:after {
  float:right;
  content: "\2212";
}

.accordionBody{
  padding:20px;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 30%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}